<template>
  <div class="lastPage">
    <img @click="back()" class="backImg" name="arrow-left" src="../../assets/img/return.png" alt="" />
    <!-- 标题 -->
<!--    <div class="titleName">-->
<!--      {{ product.productName }}-->
<!--    </div>-->
    <!-- 图片 -->
    <div v-if="product.background" class="imgs">
      <img :src="product.description" alt="" />
    </div>
    <!-- 产品详情 -->
<!--    <div v-if="product.description" class="productitem">-->
<!--      <div class="title"><span>产品详情</span></div>-->
<!--      <div v-html="product.description"></div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 产品详情 &ndash;&gt;-->
<!--    <div v-if="product.characteristic" class="productitem">-->
<!--      <div class="title"><span>产品特点</span></div>-->
<!--      <div v-html="product.characteristic"></div>-->
<!--    </div>-->
<!--    &lt;!&ndash; 产品详情 &ndash;&gt;-->
<!--    <div v-if="product.catalogue" class="productitem">-->
<!--      <div class="title"><span>产品目录</span></div>-->
<!--      <div v-html="product.catalogue"></div>-->
<!--    </div>-->
    <!-- <div class="title" style="width: 80%; margin: 0 auto">
      <div
        class="title"
        style="
          width: fit-content;
          background-color: red;
          line-height: 5vh;
          font-size: 3vw;
          color: #fff;
          font-weight: bold;
          padding: 0 10px;
          transform: skewX(-15deg);
        "
      >
        <span style="display: inline-block; transform: skewX(15deg)">标题</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { productid } from "@/api/path.js";
import backButtonVue from "../../components/backButton.vue";
import swipeTop from "@/components/swipeTop";
export default {
  components: {
    backButtonVue,
    swipeTop
  },
  data() {
    return {
      pid: 0, // 产品id
      product: {}, // 商品详情
    };
  },
  created() {
    this.pid = this.$route.query.menuid;
    this.getAllMenu();
  },
  methods: {
    getAllMenu() {
      productid(this.pid)
        .then((res) => {
          this.product = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.lastPage {
  width: 100%;
  height: 100%;

  .backImg {
    margin: 21px;
    width: 60px;
    background-color: transparent;
    // background-color:0 0 0 0.5 ;
  }

  .titleName {
    width: 80%;
    margin: 0 auto;
    display: flex;
    font-family: 'FZZZH',serif;
    justify-content: center;
    text-align: center;
    font-size: 3vh;
    padding-top: 6%;
  }

  .imgs {
    width: 100%;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  .productitem {
    width: 80%;
    margin: 0 auto;
    padding-top: 5%;
    line-height: 35px;

    .title {
      width: fit-content;
      height: 5vh;
      text-align: center;
      line-height: 5vh;
      transform: skewX(-15deg);
      background-color: red;
      font-family: 'FZZZH',serif;
      font-size: 3vw;
      color: #fff;
      font-weight: bold;
      padding: 0 20px;
      letter-spacing: 5px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        transform: skewX(15deg);
      }
    }
  }
}
</style>
